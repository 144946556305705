<template>
  <div>
    <div style="display: flex; justify-content: space-between">
      <div>
        <el-button type="primary" icon="el-icon-plus" @click="isshow = true"
          >新建内容</el-button
        >
      </div>
      <div>
        <el-form :inline="true">
          <el-form-item>
            <el-select
              v-model="searchParams.newsType"
              placeholder="请选择内容类型"
              @change="handlePageClick(1)"
              clearable
            >
              <el-option
                v-for="(item, ix) in typeList"
                :key="ix"
                :label="item.name"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="searchParams.newsTitle"
              placeholder="请输入内容标题"
              @change="handlePageClick(1)"
              clearable
              suffix-icon="el-icon-search"
              style="width: 260px"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <table-template
      :table_data="table_data"
      :formatList="['content', 'newsType', 'platformtype', 'isshow']"
      :isloading="isloading"
    >
      <template slot="content" slot-scope="scope">
        <div v-html="scope.scope.row.content"></div>
      </template>
      <template slot="newsType" slot-scope="scope">
        {{ formatType(scope.scope.row.newsType) }}
      </template>
      <template slot="platformtype" slot-scope="scope">
        {{ { 1: "金寨公司", 2: "定远公司" }[scope.scope.row.platformtype] }}
      </template>
      <template slot="isshow" slot-scope="scope">
        <el-switch
          style="display: block"
          v-model="scope.scope.row.isshow"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="0"
          active-text="展示"
          @change="handleSwitch"
          inactive-text="隐藏"
        ></el-switch>
      </template>
      <template slot-scope="scope">
        <el-button
          type="primary"
          size="small"
          @click="showEdit(scope.scope.row)"
          >编辑</el-button
        >
        <el-button
          type="danger"
          size="small"
          @click="deleteNews(scope.scope.row.id)"
          >删除</el-button
        >
      </template>
    </table-template>
    <base-pageination
      :pageinfo="table_data.pageinfo"
      @handlePageClick="handlePageClick"
      align="right"
    ></base-pageination>

    <el-dialog
      :title="opttitle"
      :visible.sync="isshow"
      width="900px"
      top="5%"
      :before-close="handleClose"
    >
      <div>
        <el-form
          :model="newsForm"
          :rules="ruleForm"
          ref="ruleForm"
          label-width="80px"
        >
          <el-form-item label="内容标题" prop="newsTitle">
            <el-input
              v-model="newsForm.newsTitle"
              placeholder="请输入内容标题"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="内容类型" prop="newsType">
            <el-select v-model="newsForm.newsType" placeholder="请选择内容类型">
              <el-option
                v-for="(item, ix) in typeList"
                :key="ix"
                :label="item.name"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属平台" prop="platformtype">
            <el-select
              v-model="newsForm.platformtype"
              placeholder="请选择所属平台"
            >
              <el-option label="金寨华润燃气有限公司" :value="1"></el-option>
              <!-- <el-option label="定远华润燃气有限公司" :value="2"></el-option> -->
            </el-select>
          </el-form-item>
          <el-form-item label="发布时间" prop="createTime">
            <el-date-picker
              v-model="newsForm.createTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择发布时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="内容文章" prop="content">
            <!-- <wang-editor-v5 @setContent="setContent"></wang-editor-v5> -->
            <wang-editor
              editorId="contentEditor"
              :height="300"
              :placeholderTxt="'请输入内容文章'"
              :propContent="newsForm.content"
              @updateEditorContent="getContent"
            ></wang-editor>
          </el-form-item>
        </el-form>
      </div>
      <div
        slot="footer"
        align="center"
        class="dialog-footer"
        style="text-align: center"
      >
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BasePageination from "../components/BasePageination.vue";
import TableTemplate from "../components/TableTemplate.vue";
import WangEditor from "../components/WangEditor/index.vue";
import {
  getNewsList,
  addNews,
  updateNews,
  deleteNews,
  getNewsById,
} from "@/api/news";
import { newsTypes } from "../js/config";
export default {
  components: { TableTemplate, BasePageination, WangEditor },
  data() {
    return {
      searchParams: {
        newsTitle: "",
        newsType: "",
      },
      table_data: {
        tableConfig: [
          { key: "newsTitle", name: "内容标题" },
          { key: "newsType", name: "内容类型", minWidth: 100, align: "center" },
          {
            key: "platformtype",
            name: "所属平台",
            minWidth: 100,
            align: "center",
          },
          {
            key: "createTime",
            name: "发布时间",
            minWidth: 150,
            align: "center",
          },
          { key: "isshow", name: "是否展示", minWidth: 200, align: "center" },
        ],
        data: [],
        pageinfo: { currentpage: 1, pageSize: 10, total: 0 },
      },
      opttitle: "新建内容",
      isshow: false,
      newsForm: {
        newsTitle: "",
        content: "",
        newsType: "",
        platformtype: 1,
        createTime: "",
      },
      ruleForm: {
        newsTitle: [
          { required: true, message: "请输入内容标题", trigger: "blur" },
        ],
        content: [
          { required: true, message: "请输入内容文章", trigger: "blur" },
        ],
        newsType: [
          { required: true, message: "请选择内容类型", trigger: "change" },
        ],
        platformtype: [
          { required: true, message: "请选择所属平台", trigger: "change" },
        ],
        createTime: [
          { required: true, message: "请选择发布时间", trigger: "change" },
        ],
      },
      isloading: false,
      typeList: [],
      platformtype: null,
    };
  },
  mounted() {
    this.typeList = newsTypes;
    if (localStorage.getItem("username") == "dingyuan") this.platformtype = 2;
    else if (localStorage.getItem("username") == "admin") this.platformtype = 1;
    this.handlePageClick(1);
  },
  methods: {
    formatType(type) {
      return this.typeList.filter((item) => item.key == type)[0].name;
    },
    handlePageClick(page) {
      this.table_data.pageinfo.currentpage = page;
      this.getList();
    },
    getList() {
      this.isloading = true;
      getNewsList({
        pageNum: this.table_data.pageinfo.currentpage,
        pageSize: this.table_data.pageinfo.pageSize,
        newsTitle: this.searchParams.newsTitle,
        newsType: this.searchParams.newsType,
        platformtype: this.platformtype,
      }).then((res) => {
        this.isloading = false;
        const { code, data, page, msg } = res;
        if (code === 10000) {
          this.table_data.data = data;
          this.table_data.pageinfo = page;
        } else {
          this.$message.warning(msg);
        }
      });
    },
    handleClose() {
      this.newsForm = {
        newsTitle: "",
        content: "",
        newsType: "",
        platformtype: 1,
      };
      this.$refs["ruleForm"].resetFields();
      this.newsForm.content = "";
      this.isshow = false;
      this.handlePageClick(1);
    },
    showEdit(row) {
      getNewsById({ id: row.id }).then((res) => {
        const { code, data } = res;
        if (code === 10000) {
          this.newsForm = Object.assign(row, {
            content: data.content,
          });
          this.opttitle = "编辑内容";
          this.isshow = true;
        }
      });
    },
    getContent(html) {
      this.newsForm.content = html;
    },
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let req = null;
          console.log(this.newsForm);
          // return
          if (this.newsForm.id) {
            req = updateNews(this.newsForm);
          } else {
            req = addNews(this.newsForm);
          }
          req.then((res) => {
            const { code, msg } = res;
            if (code === 10000) {
              this.$message.success("操作成功");
              this.handleClose();
            } else {
              this.$message.warning(msg);
            }
          });
        }
      });
    },
    deleteNews(id) {
      this.$confirm("此操作将删除该新闻, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteNews({ id }).then((res) => {
          const { code } = res;
          if (code === 10000) {
            this.$message.success("操作成功");
            this.handlePageClick(1);
          }
        });
      });
    },
    handleSwitch(val) {
      console.log(val, "----val");
    },
  },
};
</script>

<style>
.el-dialog__body {
  padding: 20px 20px 0;
}
</style>
