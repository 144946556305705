<template>
  <div class="wangeditor_">
    <div :id="editorId"></div>
    <div :ref="editorId"></div>
  </div>
</template>
<script>
/**
 * 父组件引用 同步editorData 
 * <wang-editor ref="xx"  :editorId="'xxx'" :height="400" :placeholderTxt="'xxx'"></wang-editor>
 */

// 引入 wangEditor
import WangEditor from 'wangeditor'

export default {
  props: {
    editorId: {
      type: String,
      default: 'editor'
    },
    height: {
      type: Number,
      default: 200
    },
    placeholderTxt: {
      type: String,
      default: '请输入正文内容'
    },
    menus: {
      type: Array,
      default: () => [
        'head',
        'bold',
        'fontSize',
        // 'fontName',
        'italic',
        'underline',
        'strikeThrough',
        'indent',
        'lineHeight',
        // 'foreColor',
        // 'backColor',
        'link',
        'list',
        'todo',
        'justify',
        'quote',
        // 'emoticon',
        'image',
        // 'video',
        'table',
        'code',
        'splitLine',
        'undo',
        'redo',
      ]
    },
    propContent: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      editor: null
    }
  },
  watch: {
    propContent: {
      handler(val) {
        console.log(val,'----val')
        if (!val) this.editor.txt.html('')
        else this.setEditorContent()
      }
    }
  },
  mounted () {
    this.initEditor()
  },
  methods: {
    initEditor () {
      const editor = new WangEditor('#' + this.editorId)
      // const editor = new WangEditor(this.$refs[this.editorId])
      // 挂载highlight插件
      // editor.highlight = hljs

      // 配置菜单栏，删减菜单，调整顺序
      editor.config.menus = this.menus

      // 配置行高
      editor.config.lineHeights = ['1', '1.5', '2', '2.5', '3']
      editor.config.height = this.height
      editor.config.zIndex = 10
      editor.config.menuTooltipPosition = 'down'
      editor.config.showMenuTooltips = false
      editor.config.focus = true


      // 隐藏插入网络图片的功能
      editor.config.showLinkImg = false
      // 隐藏全屏
      editor.config.showFullScreen = false

      editor.config.placeholder = this.placeholderTxt

      // // 配置触发 onchange 的时间频率，默认为 200ms
      editor.config.onchangeTimeout = 500 // 修改为 500ms
      editor.config.onchange = (html) => {
        this.$emit('updateEditorContent', html)
      }
      editor.config.onblur = newHtml => {
        this.$emit('onblur', newHtml)
        return false
      }
      editor.config.customUploadImg = (resultFiles, insertImgFn) => {
        // resultFiles 是 input 中选中的文件列表
          this.filesToBase64(resultFiles, insertImgFn)
      }
      // 创建编辑器
      editor.create()
      this.editor = editor
      this.setEditorContent()
    },
    filesToBase64 (files) {
      const _this = this
      files.map(item => {
        var reader = new FileReader()
        reader.onload = function (e) {
          _this.editor.cmd.do(
            'insertHtml',
            '<img src="' + e.target.result + '" style="max-width:100%;"/>'
          )
        }
        // 传入一个参数对象即可得到基于该参数对象的文本内容
        reader.readAsDataURL(item)
      })
    },
    setEditorContent () {
      // this.$nextTick(() => {
      // if (this.editorData) {
      this.editor.txt.html(this.propContent)
      // }
      // })
    },
  },
  beforeDestroy () {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy()
    this.editor = null

  }
}
</script>

<!-- <style lang="scss">
@import url("./css/font_2161824_78bvz3356e.css");
.wangeditor_ {
  margin: auto;
  position: relative;

  h3 {
    margin: 30px 0 15px;
  }
  .el-dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style> -->
