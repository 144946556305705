import myAxios from "./axios_news";

export const getNewsList = params => {
  return myAxios.get("/news/getNewsList", { params });
};
export const getNewsById = params => {
  return myAxios.get("/news/getNewsById", { params });
};
export const addNews = params => {
  return myAxios.post("/news/addNews", params);
};

export const updateNews = params => {
  return myAxios.post("/news/updateNews", params);
};

export const deleteNews = params => {
  return myAxios.post("/news/deleteNews", params);
};
